import { PRODUCT_TYPE_SHARED_FLIGHT } from './productTypes';

export const findMinRequiredPax = ({ data }) => {
  const seats = parseInt(data.seats);
  const isShared = parseInt(data.flight.isShared);
  let min = 1;

  if (seats > 0 && !!isShared) min = seats;

  return min;
};

export const findMaxLimitPax = ({ data }) => {
  return parseInt(data.seats) || 1;
};

export const reduceTripSegments = ({ data }) => {
  return data.flight;
};

export default {
  name: 'SharedFlightHandler',
  typeId: PRODUCT_TYPE_SHARED_FLIGHT,
  methods: {
    findMinRequiredPax,
    findMaxLimitPax,
    reduceTripSegments,
  },
};
