// Third party libs/components
import React, { useState, useRef, useEffect } from 'react';
import { useParams } from '@reach/router';
import { useStore } from 'react-redux';

//Components
import Loader from '@components/Loader';
import AppDlocalInput from '../../Inputs/AppDlocalInput';
import ResultPanel from '../../../ResultPanel';
import { setLanguage } from '@store/reducers/general/operations';

//Style
import * as Styled from './AppDLocalForm.style';

//Utils
import { translate as t } from '@utils/translate';
import useDlocal from '@utils/useDlocal';
import { apiHost, addPaymentMethodsRoute } from '@utils/constants';
import { getLangByCode } from '@utils/language';
import { postMessage } from '@utils/dlocal-app-channel';
import useRequest from '@utils/useRequest';

// Services
import { fetchCreditCardAllowedCountries } from '@services/payment';
import { STATUS_LIST } from '@store/reducers/cart/operations';
import { fetchDocumentType } from '@services/user';

const checkAllFieldsIsMounted = fields => {
  return Promise.all(
    fields.map(field => {
      return new Promise(resolve => {
        field.on('ready', () => resolve());
      });
    })
  );
};

const AppDlocalForm = () => {
  const params = useParams();
  const [createStatus, setCreateStatus] = useState(STATUS_LIST.STAND);
  const [responseMsg, setResponseMsg] = useState('');
  const [allowedCountries, setAllowedCountries] = useState(null);
  const [metadata, setMetadata] = useState(null);
  const [creditCardFormData, setCreditCardFormData] = useState(null);
  const [dlocalFields, setDlocalFields] = useState(null);
  const [documentType, setDocumentType] = useState(null);
  const panRef = useRef(null);
  const cvvRef = useRef(null);
  const expiryDateRef = useRef(null);
  const {
    dlocalInstance,
    createField,
    createToken,
    getInformation,
  } = useDlocal({
    scriptUrl: metadata ? metadata.scriptUrl : null,
    apiKey: metadata ? metadata.smartFieldApiKey : null,
    fieldSettings: {
      locale: params.lang?.toUpperCase() || 'PT',
      country: params.country,
    },
  });

  //
  // Dlocal Logic
  //
  const [panField, setPanField] = useState(null);
  const [cvvField, setCvvField] = useState(null);
  const [expirationField, setExpirationField] = useState(null);
  const [mountingField, setMountingField] = useState(true);
  const [bin, setBin] = useState(null);
  const TEXTS = {
    NEW_CARD: t('newCard'),
    NUMBER: t('cardNumber'),
    CVV: t('cvv'),
    NAME: t('cardHolderName'),
    EXPIRATION_DATE: t('validity'),
    HOLDER_DOCUMENT: t('docs'),
    CANCEL: t('cancel'),
    SAVE: t('saveButton'),
    WAIT: t('addCardWaitMsg'),
    ADDED_CARD: t('addedCard'),
    CONTINUE: t('next'),
    COUNTRY: t('country'),
    BACK: t('back'),
  };
  const [fieldValidation, setFieldValidation] = useState({
    pan: false,
    expiration: false,
    cvv: false,
    holderDocument: false,
    holderName: false,
  });
  const isFormValid = Object.values(fieldValidation).every(
    validated => validated
  );

  const store = useStore();

  useEffect(() => {
    store.dispatch(setLanguage(getLangByCode(params.lang)));

    fetchDocumentType({ countryCode: params.country }).then(res => {
      const {
        data: { documentTypes },
      } = res;
      setDocumentType(documentTypes[0]);
    });

    fetchCreditCardAllowedCountries().then(({ data }) => {
      setAllowedCountries(data.countries);
    });

    return () => {
      setCreditCardFormData(null);
    };
  }, []);

  useEffect(() => {
    if (allowedCountries != null) {
      let paymentGateway = allowedCountries.find(({ country }) => {
        return country.iso2Code == params.country;
      });

      setMetadata(paymentGateway.paymentGateway.metadata);
    }
  }, [params]);

  useEffect(() => {
    if (dlocalFields != null) {
      dlocalFields.pan.unmount();
      dlocalFields.cvv.unmount();
      dlocalFields.expiration.unmount();
    }

    if (dlocalInstance) {
      /** PAN */
      let pan = createField('pan', {
        style: {
          base: {
            fontSize: '16px',
            lineHeight: '16px',
          },
        },
        placeholder: '4111 1111 1111 1111',
        hideIcon: true,
      });
      pan.mount(panRef.current);
      setPanField(pan);

      /** CVV */
      let cvv = createField('cvv', {
        style: {
          base: {
            fontSize: '16px',
            lineHeight: '16px',
          },
        },
        placeholder: '',
      });
      cvv.mount(cvvRef.current);
      setCvvField(cvv);

      /** Expiration */
      let expiration = createField('expiration', {
        style: {
          base: {
            fontSize: '16px',
            lineHeight: '16px',
          },
        },
      });
      expiration.mount(expiryDateRef.current);
      setExpirationField(expiration);

      if (mountingField) {
        checkAllFieldsIsMounted([pan, cvv, expiration]).then(() => {
          setMountingField(false);
        });
      }
    }
  }, [dlocalInstance]);

  const handleChange = evt => {
    let { name, value } = evt.target;

    switch (name) {
      case 'expiration':
      case 'cvv':
      case 'pan':
        //Only update state if the validation change
        if (evt.complete != fieldValidation[name]) {
          setFieldValidation(oldState => ({
            ...oldState,
            [name]: evt.complete,
          }));
        }

        if (name == 'pan' && evt.complete) {
          getInformation(panField).then(b => {
            setBin(b.bin);
          });
        }

        if (name == 'pan') {
          onChange({
            ...creditCardFormData,
            cardType: evt.brand || null,
          });
        }
        break;
      default:
        //Only update state if the validation change
        let validated = !!value;
        if (validated != fieldValidation[name]) {
          setFieldValidation(oldState => ({
            ...oldState,
            [name]: validated,
          }));
        }
        onChange({
          ...creditCardFormData,
          [name]: value.length ? value : null,
        });
        break;
    }
  };

  const onChange = obj => {
    setCreditCardFormData({
      ...creditCardFormData,
      ...obj,
    });
  };

  const createCard = () => {
    setMountingField(true);

    createToken(panField, {
      name: creditCardFormData.holderName,
    })
      .then(result => {
        const creditCardPostData = {
          ...creditCardFormData,
          billingAddress: { id: params.addressId },
          token: result.token,
          country: params.country,
        };

        postMessage('TOKEN_CREATED');

        onChange({
          ...creditCardFormData,
          token: result.token,
        });

        useRequest({
          url: `${apiHost}${addPaymentMethodsRoute}`,
          method: 'post',
          useSession: true,
          sessionToken: params.pk,
          data: { creditCard: creditCardPostData },
        })
          .then(({ data }) => {
            if (data.result.status === 0) {
              postMessage('CREDIT_CARD_CREATED');

              setResponseMsg(TEXTS.ADDED_CARD);
              setCreateStatus(STATUS_LIST.FETCHED);
              setMountingField(false);
            } else {
              postMessage('CREDIT_CARD_FAILED', '*', data.msg);
              setResponseMsg(data.msg);
              setCreateStatus(STATUS_LIST.ERROR);
              setMountingField(false);
            }
          })
          .catch(err => {
            postMessage('CREDIT_CARD_FAILED', '*', err);
            setResponseMsg(t('somethingWrongMsg'));
            console.error(err);
            setCreateStatus(STATUS_LIST.ERROR);
            setMountingField(false);
          });
      })
      .catch(err => {
        postMessage('TOKEN_FAILED', '*', err);
        console.error(err);
        setResponseMsg(err);
        setCreateStatus(STATUS_LIST.ERROR);
        setMountingField(false);
      });
  };

  const validateForm = () => {};

  const handleBackToForm = () => {
    setCreateStatus(STATUS_LIST.STAND);
  };

  const handleFinish = () => {
    setCreateStatus(STATUS_LIST.STAND);
  };

  const renderResultPanel = () => {
    switch (createStatus) {
      case STATUS_LIST.IS_FETCHING:
        return (
          <Loader customStyles={{ paddingLeft: '200px', height: '220px' }}>
            <p>{TEXTS.WAIT}</p>
          </Loader>
        );
      case STATUS_LIST.FETCHED:
        return (
          <ResultPanel
            type={'success'}
            buttonLabel={TEXTS.CONTINUE}
            title={TEXTS.ADDED_CARD}
            onClick={handleFinish}
          />
        );

      default:
        return (
          <ResultPanel
            type={'error'}
            buttonLabel={TEXTS.BACK}
            title={responseMsg}
            description={''}
            onClick={handleBackToForm}
          />
        );
    }
  };

  return (
    <Styled.FormContainer>
      <Styled.HeaderContainer></Styled.HeaderContainer>

      <Styled.FormInputsWrapper hidden={createStatus != STATUS_LIST.STAND}>
        <Styled.Row>
          <Styled.InputContainer>
            <AppDlocalInput
              name="pan"
              tabIndex="1"
              input={panField}
              inputRef={panRef}
              onChange={handleChange}
            >
              <span>{TEXTS.NUMBER}</span>
            </AppDlocalInput>
          </Styled.InputContainer>
        </Styled.Row>

        <Styled.Row>
          <Styled.InputContainer>
            <AppDlocalInput
              name="expiration"
              input={expirationField}
              inputRef={expiryDateRef}
              tabIndex="2"
              onChange={handleChange}
            >
              <span>{TEXTS.EXPIRATION_DATE}</span>
            </AppDlocalInput>
          </Styled.InputContainer>

          <Styled.InputContainer>
            <AppDlocalInput
              name="cvv"
              input={cvvField}
              inputRef={cvvRef}
              tabIndex="3"
              onChange={handleChange}
            >
              <span>{TEXTS.CVV}</span>
            </AppDlocalInput>
          </Styled.InputContainer>
        </Styled.Row>

        <Styled.Row>
          <Styled.InputContainer>
            <label>
              <span>{TEXTS.NAME}</span>
              <input
                tabIndex="4"
                onChange={handleChange}
                onKeyUp={validateForm}
                defaultValue={creditCardFormData?.holderName || ''}
                name="holderName"
                type="text"
                placeholder="Ex: James Smith"
                maxLength="25"
              />
            </label>
          </Styled.InputContainer>
        </Styled.Row>

        <Styled.Row>
          <Styled.InputContainer>
            <label>
              <span>
                {documentType?.code.includes('BR')
                  ? TEXTS.HOLDER_DOCUMENT
                  : documentType?.name}
              </span>
              <input
                tabIndex="5"
                onChange={handleChange}
                onKeyUp={validateForm}
                defaultValue={creditCardFormData?.holderDocument || ''}
                name="holderDocument"
                type="text"
                placeholder=""
                maxLength="25"
              />
            </label>
          </Styled.InputContainer>
        </Styled.Row>

        <Styled.ErrorContainer
          id="card-errors"
          role="alert"
        ></Styled.ErrorContainer>

        <Styled.BtnContainer>
          <Styled.Button
            theme={isFormValid ? 'active' : 'disabled'}
            type="button"
            onClick={createCard}
          >
            {TEXTS.SAVE}
          </Styled.Button>
        </Styled.BtnContainer>
      </Styled.FormInputsWrapper>

      <Loader show={mountingField} />
      {createStatus != STATUS_LIST.STAND && renderResultPanel()}
    </Styled.FormContainer>
  );
};

export default AppDlocalForm;
