import styled, { css } from 'styled-components';

const COLORS = {
  white: '#ffffff',
  ligthGrey: '#E0E0E0',
  focusColor: '#01bfa5',
  darkGrey: '#858585',
  labelSmall: '##BDBDBD',
  buttonGreen: '#00897b',
  disabledGrey: '#E0E0E0',
};

export const FormContainer = styled.div`
  margin: 0 auto;
  max-width: 400px;
  padding-bottom: 30px;
`;

export const FormInputsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${({ hidden }) =>
    hidden
      ? css`
          display: none;
        `
      : ''};
`;

export const InputContainer = styled.div`
  width: 100%;
  position: relative;
  margin-right: 10px;

  span {
    position: absolute;
    color: ${COLORS.labelSmall};
    font-size: 0.7em;
    font-weight: 400;
    top: -17px;
    background: white;
    padding: 0px 5px;
    left: -5px;
  }

  input {
    border: none;
    border-bottom: 1px solid ${COLORS.ligthGrey};
    transition: border-color 0.2s ease-in-out;
    font-size: 17px;
    font-weight: 500;
    width: 100%;
    margin-bottom: 50px;
    padding: 5px 0 10px 0;
    box-sizing: border-box;

    &:focus {
      border-color: ${COLORS.focusColor};
    }

    ::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: #5f6767;
      font-weight: 500;
      font-size: 1.1em;
      opacity: 0.8; /* Firefox */
      line-height: 1;
    }

    :-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: red;
    }

    ::-ms-input-placeholder {
      /* Microsoft Edge */
      color: red;
    }
  }
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 90%;
`;

export const HeaderContainer = styled.div`
  height: 30px;
`;

export const Title = styled.span`
  margin-left: 20px;
`;

export const ErrorContainer = styled.div`
  height: 0px;
`;

const buttonDefaultTheme = css`
  background-color: ${COLORS.white};
  border: 2px solid ${COLORS.buttonGreen};
  color: ${COLORS.buttonGreen};
`;

const buttonDisabledTheme = css`
  background-color: ${COLORS.disabledGrey};
  border: 2px solid ${COLORS.disabledGrey};
  pointer-events: none;
  color: ${COLORS.darkGrey};
`;

const buttonActiveTheme = css`
  background-color: ${COLORS.buttonGreen};
  border: 2px solid ${COLORS.buttonGreen};
  color: ${COLORS.white};
  box-shadow: 0px 2px 3px #0005;
`;

const getButtonTheme = theme => {
  return {
    default: buttonDefaultTheme,
    disabled: buttonDisabledTheme,
    active: buttonActiveTheme,
  }[theme];
};

export const BtnContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 90%;
`;

export const Button = styled.button`
  border-radius: 2px;
  cursor: pointer;
  font-size: 0.8em;
  line-height: 21px;
  font-family: 'Montserrat';
  font-weight: 500;
  padding: 7px 5px;
  transition: all 0.4s ease-in-out;
  width: 100%;

  ${({ theme }) => getButtonTheme(theme)};
`;
