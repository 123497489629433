import { useEffect, useState } from 'react';

import loadScript from '@utils/loadScript';

const useDlocal = ({ scriptUrl, apiKey, fieldSettings, onLoad }) => {
  const [dlocalState, setDlocalState] = useState({
    dlocalInstance: null,
    fieldCreator: null,
  });

  const { locale, country } = fieldSettings;
  const { dlocalInstance, fieldCreator } = dlocalState;

  const createField = (name, settings = {}, target) => {
    return fieldCreator.create(name, settings);
  };

  const createToken = (field, options) => {
    return dlocalInstance.createToken(field, options);
  };

  const getInformation = field => {
    return dlocalInstance.getBinInformation(field);
  };

  useEffect(() => {
    if (scriptUrl) {
      loadScript(scriptUrl).then(() => {
        let dlocalInstance = dlocal(apiKey);
        let fieldCreator = dlocalInstance.fields(fieldSettings);

        setDlocalState({ dlocalInstance, fieldCreator });
      });
    }
  }, [locale, country, apiKey, scriptUrl]);

  return {
    dlocalInstance: dlocalState.dlocalInstance,
    fieldCreator: dlocalState.fieldCreator,
    createToken,
    createField,
    getInformation,
  };
};

export default useDlocal;
