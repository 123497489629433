//import requireContext from 'require-context';
import quotationProposal from './quotationProposal.handler';
import sharedFlight from './sharedFlight.handler';

export const PRODUCT_HANDLERS = {
  [quotationProposal.typeId]: quotationProposal,
  [sharedFlight.typeId]: sharedFlight,
};

const REQUIRED_METHODS = [
  'findMinRequiredPax',
  'findMaxLimitPax',
  'reduceTripSegments',
];

/**
 * Find and validate the product handlers interface
 */
for (let key in PRODUCT_HANDLERS) {
  if (PRODUCT_HANDLERS.hasOwnProperty(key)) {
    const productHandler = PRODUCT_HANDLERS[key];

    REQUIRED_METHODS.forEach(method => {
      if (method in productHandler.methods == false) {
        throw new Error(
          `Invalid product handler ${productHandler.name} doenst have the method ${method}`
        );
      }
    });
  }
}

// let productHandler = null;
// const pageToBuild = requireContext(path.resolve('./'), false, /\.handler\.js$/);
// for (let fileName of pageToBuild.keys()) {
//   productHandler = require(`./${fileName}`);

//   PRODUCT_HANDLERS[productHandler.typeId] = productHandler;
// }

/**
 *
 * Lookup at array of products and find
 * the min required amount of pax
 *
 * @param {*} products
 * @return int
 */
export const findMinRequiredPax = (products = []) => {
  return products.reduce((minSeatReq, product) => {
    if (product.typeId in PRODUCT_HANDLERS) {
      let seats = PRODUCT_HANDLERS[product.typeId].methods.findMinRequiredPax(
        product
      );
      if (minSeatReq == null || minSeatReq > seats) return seats;
    }
    return minSeatReq;
  }, 9999);
};

/**
 *
 * Lookup at array of products and find
 * the max limit amount of pax
 *
 * @param {*} products
 * @return int
 */
export const findMaxLimitPax = (products = []) => {
  return products.reduce((maxLimitPax, product) => {
    if (product.typeId in PRODUCT_HANDLERS) {
      let seats = PRODUCT_HANDLERS[product.typeId].methods.findMaxLimitPax(
        product
      );
      if (maxLimitPax == null || maxLimitPax > seats) return seats;
    }
    return maxLimitPax;
  }, 9999);
};

/**
 * Reduce the list of products to a list of segments
 *
 * @param {*} products
 */
export const reduceTripSegments = (products = []) => {
  return products.reduce((acc, product) => {
    if (product.typeId in PRODUCT_HANDLERS) {
      let segments = PRODUCT_HANDLERS[
        product.typeId
      ].methods.reduceTripSegments(product);
      acc = acc.concat(segments);
    }
    return acc;
  }, []);
};
