// Third party libs/components
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

//Style
import * as Styled from './AppDlocalInput.styles';

const AppDlocalInput = ({
  name,
  inputRef,
  input,
  children,
  onChange,
  ...restProp
}) => {
  useEffect(() => {
    if (input) {
      input.on('change', evt => {
        onChange({
          ...evt,
          target: {
            name,
          },
        });
      });
    }
  }, [input]);

  return (
    <Styled.Label {...restProp}>
      {children}
      <Styled.InputWrapper ref={inputRef}></Styled.InputWrapper>
    </Styled.Label>
  );
};

AppDlocalInput.propTypes = {
  onChange: PropTypes.func,
};

AppDlocalInput.defaultProps = {
  onChange: () => {},
};

export default AppDlocalInput;
