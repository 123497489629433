// Third party libs/components
import React from 'react';

// Style
import * as Styled from './ResultPanel.styles';

const ResultPanel = ({ type, onClick, title, description, buttonLabel }) => {
  const renderResultPanel = () => {
    const successPanel = () => (
      <Styled.SuccessMessage>
        <h1>{title}</h1>
        <Styled.Button theme={'active'} type="button" onClick={onClick}>
          {buttonLabel}
        </Styled.Button>
      </Styled.SuccessMessage>
    );

    const errorPanel = () => (
      <Styled.ErrorMessage>
        <h1>{title}</h1>
        <h4>{description}</h4>
        <Styled.Button theme={'default'} type="button" onClick={onClick}>
          {buttonLabel}
        </Styled.Button>
      </Styled.ErrorMessage>
    );

    switch (type) {
      case 'success':
        return successPanel();
      case 'error':
        return errorPanel();

      default:
        return errorPanel();
    }
  };

  return renderResultPanel();
};

export default ResultPanel;
