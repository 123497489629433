import { PRODUCT_TYPE_QUOTATION_PROPOSAL } from './productTypes';
export const findMinRequiredPax = ({ data }) => {
  return 1;
};

export const findMaxLimitPax = ({ data }) => {
  return parseInt(
    data.segments.reduce((limitPax, { aircraft }) => {
      let aircraftSeats =
        aircraft.quantityOfPassengers || aircraft.model.quantityOfPassengers;
      aircraftSeats = parseInt(aircraftSeats);
      return aircraftSeats < limitPax ? aircraftSeats : limitPax;
    }, 9999)
  );
};

export const reduceTripSegments = ({ data }) => {
  return data.segments.filter(s => s.emptyLeg == 0);
};

export default {
  name: 'QuotationProposalHandler',
  typeId: PRODUCT_TYPE_QUOTATION_PROPOSAL,
  methods: {
    findMinRequiredPax,
    findMaxLimitPax,
    reduceTripSegments,
  },
};
