import React from 'react';
import { Router } from '@reach/router';

// Components
import AppDlocalForm from '@components/AppComponents/Forms/CreditCardForm/DLocalForm/AppDLocalForm';
import AppCaptcha from '@components/AppComponents/AppCaptcha';
import Layout from '@components/layout';

const Plataform = ({ location }) => {
  const path = location.pathname;

  const translations = [
    { path: path, polylang_current_lang: 'en_US' },
    { path: path, polylang_current_lang: 'es_ES' },
    { path: path, polylang_current_lang: 'pt_BR' },
    // { path: path, polylang_current_lang: 'it_IT' },
  ];

  return (
    <Layout
      solidHeader="transparent"
      showNewsletter={false}
      showFooter={false}
      showHeader={false}
      showWhatsappButton={false}
      translations={translations}
      useTranslationsPath={true}
    >
      <Router basepath="/app-container/:lang">
        <AppDlocalForm path="/dlocal-form/:country/:addressId/:pk" />
        <AppCaptcha path="captcha" />
      </Router>
    </Layout>
  );
};

export default Plataform;
