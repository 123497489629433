import styled from 'styled-components';

const COLORS = {
  white: '#ffffff',
  ligthGrey: '#E0E0E0',
  focusColor: '#01bfa5',
};

const Label = styled.label`
  color: ${COLORS.darkGrey};
  display: flex;
  flex-direction: column;
  font-family: 'Montserrat';
  font-weight: 600;
  margin-bottom: 50px;
  position: relative;
  width: 100%;

  transition: border-color 0.2s ease-in-out;
  -webkit-appearance: none;

  ${({ customStyle }) => {
    return customStyle;
  }};

  span {
    position: absolute;
    color: ${COLORS.labelSmall};
    font-size: 0.7em;
    font-weight: 400;
    top: -17px;
    background: white;
    padding: 0px 5px;
    left: -5px;
  }
`;

const InputWrapper = styled.div`
  .DlocalField {
    border-bottom: 1px solid ${COLORS.ligthGrey};
    transition: border-color 0.2s ease-in-out;
    height: 32px;
    padding: 5px 0 10px 0;

    > div {
      height: 25px !important;
    }

    &.DlocalField--focus {
      border-color: ${COLORS.focusColor};
    }
  }
`;

export { Label, InputWrapper };
