import {
  apiHost,
  execPaymentRoute,
  creditCardAllowedCountriesRoute,
} from '@utils/constants';
import useRequest from '@utils/useRequest';

/**
 * Executes payment
 *
 * @param {object} cart Cart data (token, products)
 * @param {object} invoice Invoice data (document, documentTypeId, address)
 * @param {array} passengers Array of objects (Passenger)
 * @param {array} payments Array of objects (PurchaseCreditCard)
 */
function execPayment({ cart, invoice, passengers, payments }) {
  const postData = {
    order: {
      cart,
      invoice,
      passengers,
      payments,
    },
  };

  return new Promise((resolve, reject) => {
    useRequest({
      url: `${apiHost}${execPaymentRoute}`,
      method: 'post',
      data: postData,
    })
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        reject(err);
      });
  });
}

/**
 * Executes payment for shared flights
 *
 * @param {object} flights Array of flights (flightToken, seats)
 * @param {object} invoice Invoice data (document, documentTypeId, address)
 * @param {array} passengers Array of objects (Passenger)
 * @param {array} payments Array of objects (PurchaseCreditCard)
 */
function execPaymentForSharedFlights({
  flights,
  invoice,
  passengers,
  payments,
}) {
  const postData = {
    flights,
    order: {
      invoice,
      passengers,
      payments,
    },
  };

  return new Promise((resolve, reject) => {
    useRequest({
      url: `${apiHost}${execPaymentRoute}`,
      method: 'post',
      data: postData,
    })
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        reject(err);
      });
  });
}

function fetchCreditCardAllowedCountries() {
  return new Promise((resolve, reject) => {
    useRequest({
      url: `${apiHost}${creditCardAllowedCountriesRoute}`,
      method: 'get',
      useSession: false,
    })
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        reject(err);
      });
  });
}

export {
  execPayment,
  execPaymentForSharedFlights,
  fetchCreditCardAllowedCountries,
};
