// Third party libs/components
import React, { useRef, useEffect } from 'react';
import Reaptcha from 'reaptcha';
import { Helmet } from 'react-helmet';

// Style
import * as Styled from './AppCaptcha.styles';

// Utils
import { RECAPTCHA_SITE_KEY_APP } from '@gatsby-local-plugin/gatsby-local-env-variables';
import { useParams } from '@reach/router';

const AppCaptcha = () => {
  var captcha = useRef();

  var { lang } = useParams();

  var verifyCaptcha = () => {
    captcha.current.getResponse().then(res => {
      if (res) {
        if (typeof RecaptchaChannel !== 'undefined') {
          RecaptchaChannel.postMessage(res);
        }
      }
    });
  };

  return (
    <>
      <Helmet>
        <script
          src={`https://www.google.com/recaptcha/api.js?render=${RECAPTCHA_SITE_KEY_APP}`}
          async
          defer
        ></script>
      </Helmet>
      <Styled.AppCaptchaContainer>
        <Reaptcha
          ref={captcha}
          sitekey={RECAPTCHA_SITE_KEY_APP}
          onVerify={verifyCaptcha}
          hl={lang || 'pt-BR'}
        />
      </Styled.AppCaptchaContainer>
    </>
  );
};

export default AppCaptcha;
