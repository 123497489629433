import isBrowser from '@utils/isBrowser';

let SCRIPTS_REGISTER = {};

/**
 *
 * Check if the file is already registred
 * @param {string} src
 * @returns boolean
 */
const isRegistred = src => {
  return src in SCRIPTS_REGISTER;
};

/**
 *
 * Check if the file is already loaded
 * @param {string} src
 * @returns boolean
 */
const isLoaded = src => {
  if (!isRegistred(src)) {
    return false;
  }

  let script = SCRIPTS_REGISTER[src];
  return script.isLoaded;
};

/**
 * Execute resolve all pending promises
 *
 * @param {string} src
 * @returns boolean
 */
const resolvePromiseScript = src => {
  if (isRegistred(src)) {
    SCRIPTS_REGISTER[src].resolve.forEach(resolve => resolve());
  }
};

/**
 * Execute reject all pending promises
 *
 * @param {string} src
 * @returns boolean
 */
const rejectPromiseScript = src => {
  if (isRegistred(src)) {
    SCRIPTS_REGISTER[src].reject.forEach(reject => reject());
  }
};

/**
 * Register the file to load
 *
 * @param {string} src
 * @returns boolean
 */
const registerScriptToLoad = (src, { attrs, resolve, reject }) => {
  if (isRegistred(src)) {
    if (isLoaded(src)) {
      resolve();
    } else {
      SCRIPTS_REGISTER[src].resolve.push(resolve);
      SCRIPTS_REGISTER[src].reject.push(reject);
    }
    return;
  }

  let el = document.createElement('script');
  el.src = src;
  el.onload = function() {
    SCRIPTS_REGISTER[src].isLoaded = true;
    resolvePromiseScript(src);
  };
  for (let key in attrs) {
    el.setAttribute(key, attrs[key]);
  }
  el.onerror = function() {
    rejectPromiseScript(src);
  };
  document.querySelector('head').appendChild(el);

  SCRIPTS_REGISTER[src] = {
    isLoaded: false,
    script: src,
    el: el,
    resolve: [resolve],
    reject: [reject],
  };
};

/**
 *
 * @param {string} src
 * @returns Promise
 */
const loadScript = (src, { attrs = {} } = {}) => {
  if (!isBrowser()) {
    return Promise.resolve(1);
  }
  return new Promise((resolve, reject) => {
    registerScriptToLoad(src, { attrs, resolve, reject });
  });
};

export default loadScript;
