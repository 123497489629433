import styled, { css, keyframes } from 'styled-components';

const COLORS = {
  white: '#ffffff',
  ligthGrey: '#E0E0E0',
  darkGrey: '#858585',
  labelSmall: '##BDBDBD',
  buttonGreen: '#01BEA4',
  buttonRed: '#ff4e4e',
  disabledGrey: '#E0E0E0',
};

const successMessageAnimation = keyframes`
  0% { opacity: 0; top: 20px; }
  100% { opacity: 1; top: 0; }
`;

const buttonDefaultTheme = css`
  background-color: ${COLORS.white};
  border: 2px solid ${COLORS.buttonGreen};
  color: ${COLORS.buttonGreen};
`;

const buttonDisabledTheme = css`
  background-color: ${COLORS.disabledGrey};
  border: 2px solid ${COLORS.disabledGrey};
  pointer-events: none;
  color: ${COLORS.darkGrey};
`;

const buttonActiveTheme = css`
  background-color: ${COLORS.buttonGreen};
  border: 2px solid ${COLORS.buttonGreen};
  color: ${COLORS.white};
`;

const getButtonTheme = theme => {
  return {
    default: buttonDefaultTheme,
    disabled: buttonDisabledTheme,
    active: buttonActiveTheme,
  }[theme];
};

const Button = styled.button`
  border-radius: 8px;
  cursor: pointer;
  font-size: 1.4em;
  font-family: 'Montserrat';
  font-weight: 600;
  margin-left: 17px;
  padding: 14px 0;
  transition: all 0.4s ease-in-out;
  width: 185px;

  ${({ theme }) => getButtonTheme(theme)};
`;

const SuccessMessage = styled.div`
  animation: ${successMessageAnimation} 0.5s 0s both;
  align-items: center;
  display: flex;
  font-size: 10px;
  flex-direction: column;
  justify-content: center;
  min-height: 200px;
  position: relative;

  h1 {
    color: ${COLORS.buttonGreen};
    font-family: 'Montserrat';
    font-size: 2.5em;
    font-weight: 600;
  }
`;

const ErrorMessage = styled.div`
  animation: ${successMessageAnimation} 0.5s 0s both;
  align-items: center;
  display: flex;
  font-size: 10px;
  flex-direction: column;
  justify-content: center;
  min-height: 200px;
  position: relative;

  h1 {
    color: ${COLORS.buttonRed};
    font-family: 'Montserrat';
    font-size: 2.5em;
    font-weight: 600;
  }
`;

export { Button, SuccessMessage, ErrorMessage };
