import { Creators } from './actions';
import useRequest from '@utils/useRequest';
import objectToQueryString from '@utils/objectToQueryString';
import {
  apiHost,
  paymentMethodsRoute,
  getInstallments,
  getUser,
  addPaymentMethodsRoute,
} from '@utils/constants';
import { findMaxLimitPax, findMinRequiredPax } from './productHandler/index';
import { batch } from 'react-redux';

import { addError } from '@store/reducers/errors/operations';

export const STATUS_LIST = {
  STAND: 'stand',
  IS_FETCHING: 'fetching',
  FETCHED: 'fetched',
  ERROR: 'error',
};

export const reset = Creators.reset;
export const setPaymentMethodsList = Creators.setPaymentMethodsList;
export const setSelectedPaymentMethod = Creators.setSelectedPaymentMethod;
export const setPassengersList = Creators.setPassengersList;
export const toggleSelectedPassenger = Creators.toggleSelectedPassenger;
export const updatePassenger = Creators.updatePassenger;
export const setBillingAddress = Creators.setBillingAddress;
export const setCardBrands = Creators.setCardBrands;
export const setSeats = Creators.setSeats;
export const setInvoicingAddress = Creators.setInvoicingAddress;
export const setCreditCardFormData = Creators.setCreditCardFormData;
export const setStatus = Creators.setStatus;

export const fetchCartData = token => {
  return () => {
    return new Promise((resolve, reject) => {
      useRequest({
        url: `${apiHost}/api/v1/cart/get?token=${token}`,
        //mockup: CART_MOCKUP,
        method: 'get',
        useSession: true,
      })
        .then(({ data }) => {
          resolve(data);
        })
        .catch(err => {
          reject(err);
        });
    });
  };
};

export const setCartData = cartData => {
  return dispatch => {
    batch(() => {
      dispatch(Creators.setCartData(cartData));
      const maxPax = findMaxLimitPax(cartData.products);
      const minPax = findMinRequiredPax(cartData.products);
      dispatch(Creators.setSeats(maxPax, 0, minPax));
      // Handle min required fields
    });
  };
};

export const fetchPaymentMethods = () => {
  return () => {
    return new Promise((resolve, reject) => {
      useRequest({
        url: `${apiHost}${paymentMethodsRoute}`,
        method: 'get',
        useSession: true,
      })
        .then(({ data }) => {
          resolve(data);
        })
        .catch(err => {
          reject(err);
        });
    });
  };
};

export const fetchPassengersList = () => {
  return () => {
    return new Promise((resolve, reject) => {
      useRequest({
        url: `${apiHost}/api/v1/passenger/get`,
        method: 'get',
        useSession: true,
      })
        .then(({ data }) => {
          resolve(data);
        })
        .catch(err => {
          reject(err);
        });
    });
  };
};

export const addPassenger = passenger => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      useRequest({
        url: `${apiHost}/api/v1/passenger/create`,
        method: 'post',
        useSession: true,
        data: { passenger: { ...passenger, saveForNextFlights: 1 } },
      })
        .then(({ data }) => {
          dispatch(Creators.addPassenger(data.passenger));
          resolve(data);
        })
        .catch(err => {
          reject(err);
        });
    });
  };
};

export const putPassenger = passenger => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      useRequest({
        url: `${apiHost}/api/v1/passenger/update`,
        method: 'put',
        useSession: true,
        data: { passenger: { ...passenger } },
      })
        .then(({ data }) => {
          dispatch(Creators.updatePassenger(data.passenger));
          resolve(data);
        })
        .catch(err => {
          reject(err);
        });
    });
  };
};

export const fetchInstallments = ({ cartToken, currency, cardIds }) => {
  return () => {
    return new Promise((resolve, reject) => {
      useRequest({
        url: `${apiHost}${getInstallments}?${objectToQueryString({
          cartToken,
          currency,
          cardIds,
        })}`,
        method: 'get',
      })
        .then(({ data }) => {
          resolve(data);
        })
        .catch(err => {
          reject(err);
        });
    });
  };
};

export const setSelectedInstallments = installment => {
  return dispatch => {
    dispatch(Creators.setSelectedInstallments(installment));
  };
};

export const setSelectedCardCode = code => {
  return dispatch => {
    dispatch(Creators.setSelectedCardCode(code));
  };
};

export const fetchUserData = () => {
  return () => {
    return new Promise((resolve, reject) => {
      useRequest({
        url: `${apiHost}${getUser}`,
        method: 'get',
      })
        .then(({ data }) => {
          resolve(data);
        })
        .catch(err => {
          reject(err);
        });
    });
  };
};

export const deletePaymentMethod = cardId => {
  return (dispatch, getState) => {
    const state = getState();

    return new Promise((resolve, reject) => {
      useRequest({
        url: `${apiHost}/api/v1/payment/deletepaymentmethod?id=${cardId}`,
        method: 'delete',
        useSession: true,
      })
        .then(({ data }) => {
          const paymentMethodsWithoutDeletedItem = state.cart.paymentMethods.filter(
            paymentMethod => paymentMethod.card.id !== cardId
          );
          dispatch(
            Creators.setPaymentMethodsList(paymentMethodsWithoutDeletedItem)
          );
          resolve(data);
        })
        .catch(err => {
          reject(err);
        });
    });
  };
};

export const addCreditCard = ({
  token,
  number,
  cvv,
  expiryDate,
  holderDocument,
  holderName,
  billingAddress,
  country,
  sessionToken = '',
}) => {
  let postData = {
    country,
    holderDocument,
    cvv,
    expiryDate,
    holderName,
    billingAddress,
  };

  if (token) {
    postData.token = token;
  } else {
    postData.number = number;
  }

  return dispatch => {
    useRequest({
      url: `${apiHost}${addPaymentMethodsRoute}`,
      method: 'post',
      sessionToken: sessionToken,
      data: { creditCard: postData },
    })
      .then(({ data }) => {
        if (data.result.status === 0) {
          // success
          const { creditCard: cardInfo } = data;
          const cardObj = {
            type: 'CreditCard',
            description: `${cardInfo.cardBrand.name} ${cardInfo.lastDigits}`,
            icon: {
              type: 'image',
              url: cardInfo.cardBrand.imageUrl,
            },
            card: { ...cardInfo },
          };
          dispatch(Creators.addCreditCard(cardObj));
        } else {
          // error
          dispatch(Creators.setStatus(STATUS_LIST.ERROR));
          dispatch(addError(data.msg, 'add-credit-card'));
        }
      })
      .catch(err => {
        // unknown errors
        dispatch(Creators.setStatus(STATUS_LIST.ERROR));
        dispatch(addError(err, 'add-credit-card'));
        console.log(err);
      });
  };
};

export const setUserData = ({ value }) => {
  return dispatch => {
    dispatch(Creators.setUserData(value));
  };
};

export const updateUserData = value => {
  return dispatch => {
    dispatch(Creators.updateUserData(value));
  };
};

export default {
  fetchCartData,
  setCartData,
  // payment methods
  fetchPaymentMethods,
  fetchInstallments,
  fetchUserData,
  setUserData,
  updateUserData,
  setSelectedPaymentMethod,
  setSelectedCardCode,
  setPaymentMethodsList,
  setStatus,
  // passengers
  setSeats,
  fetchPassengersList,
  setPassengersList,
  toggleSelectedPassenger,
  setBillingAddress,
  setCardBrands,
  setInvoicingAddress,
  reset,
};
